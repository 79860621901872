<template>
  <main v-if="campaign" class="main">
    <div class="c-container">
      <div class="flex items-center mb-16 w-full">
        <Avatar v-if="brandImages[campaign.brand.id]" :src="brandImages[campaign.brand.id]" size="large" class="mr-4" />

        <div>
          <Title size="large" :title="campaign.title" />

          <span class="title__label">Ad campaign</span>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
        <KPI
          label="Streamers"
          title="Total amount of streamers part of campaign"
          :value="campaign && campaign.handles && campaign.handles.length ? campaign.handles.length : 0"
        />

        <KPI
          label="Average viewers"
          :value="getTotalAverageViews"
          title="Average amount of views for each ad display"
        />

        <KPI label="Ad views" :value="getTotalViews" title="Total ad views" />

        <KPI label="Link clicks" :value="getTotalLinkClick" title="Amount of times campaign link has been clicked" />

        <KPI label="Link CTR" :value="getTotalLinkCtr" unit="%" title="Click-through rate ((clicks / views) * 100)" />

        <KPI label="Times displayed" :value="getTotalTimesDisplayed" title="Amount of times ad has been displayed" />
      </div>

      <div class="flex items-center mb-8 w-full">
        <Title size="medium" title="Streamer stats" />

        <div class="flex ml-auto">
          <Button
            class="ml-10"
            kind="link"
            title="A streamer count as active if they have delivered any amount of views"
            :label="showInactiveStreamers ? 'Hide inactive streamers' : 'Show inactive streamers'"
            @clicked="showInactiveStreamers = !showInactiveStreamers"
          />

          <Button class="ml-10" kind="secondary" label="Export CSV" @clicked="exportStreamerDataCSV" />
        </div>
      </div>

      <div class="flex mb-16 w-full">
        <List
          class="list--campaigns"
          :sort="[
            {
              label: 'Handle',
              prop: 'date',
              active: true,
              emit: 'sortByHandle',
            },
            {
              label: 'Views',
              prop: 'streamerAmount',
              align: 'center',
              emit: 'sortByViews',
            },
            {
              label: 'Clicks',
              prop: 'date',
              align: 'center',
              emit: 'sortByClicks',
            },
            {
              label: 'CTR',
              prop: 'date',
              align: 'center',
              emit: 'sortByCTR',
            },
            {
              label: 'Times displayed',
              prop: 'date',
              align: 'center',
              emit: 'sortByTimesDisplayed',
            },
            {
              label: '',
              prop: '',
            },
          ]"
          @sortByHandle="handleSort('handle')"
          @sortByViews="handleSort('views')"
          @sortByClicks="handleSort('clicks')"
          @sortByCTR="handleSort('ctr')"
          @sortByTimesDisplayed="handleSort('timesDisplayed')"
        >
          <li v-for="(handle, i) of computedCampaign.handles" :key="i" class="list__item">
            <div class="list__item-img">
              <img
                v-if="handle.handle"
                v-lazy="`https://storage.googleapis.com/cavea-avatar-service/twitch/${handle.handle}/avatar.png`"
                :alt="`${handle.handle} profile image`"
                @error="profileImageFallback"
              />
            </div>

            <div class="list__item-content">
              <div class="list__item-col" data-col="1">
                <Button
                  v-if="handle.handle"
                  class="flex mb-2 text-base font-medium button pointer"
                  :url="`/profile/${handle.handle}`"
                  kind="link"
                  :new-window="false"
                  :capitalize="true"
                >
                  <div
                    class="mr-2"
                    :style="` width:10px; height: 10px; border-radius: 50%; background: ${
                      handleStreamStatus(handle.handle) ? '#5ece8a' : '#d64646'
                    }; `"
                  />

                  {{ handle.handle }}

                  <Tag v-if="!handle.active" label="DISABLED" class="ml-2 bg-signal-red" />
                </Button>

                <div class="flex items-center w-full">
                  <span v-if="handle.platform" class="mr-4 text-xs capitalize ellipsis color--blue-lighter">
                    {{ handle.platform }}
                  </span>
                </div>
              </div>

              <div class="items-center list__item-col" data-col="2">
                <Button
                  v-if="handle.handle"
                  class="mb-2 text-sm font-medium button ellipsis"
                  kind="link"
                  :label="getStreamerViews(handle.handle).toString() || '0'"
                />
              </div>

              <div class="items-center list__item-col" data-col="3">
                <Button
                  v-if="handle.handle"
                  class="mb-2 text-sm font-medium button ellipsis"
                  kind="link"
                  :label="getStreamerClicks(handle.handle).toString()"
                />
              </div>

              <div class="items-center list__item-col" data-col="4">
                <Button
                  v-if="handle.handle"
                  class="mb-2 text-sm font-medium button ellipsis"
                  kind="link"
                  :label="`${getStreamerCtr(handle.handle)}%`"
                />
              </div>

              <div class="items-center list__item-col" data-col="5">
                <Button
                  v-if="handle.handle"
                  class="mb-2 text-sm font-medium button ellipsis"
                  kind="link"
                  :label="getStreamerDisplayTimes(handle.handle).toString()"
                />
              </div>

              <div class="list__item-col" data-col="0">
                <Dropdown
                  :position="['right']"
                  :items="[
                    {
                      index: i,
                      id: `${handle.contentCreatorId || null}`,
                      label: 'Copy stream code',
                    },
                    {
                      index: i,
                      id: `${handle.contentCreatorId || null}`,
                      label: handle.active ? 'Disable handle' : 'Enable handle',
                    },
                  ]"
                  :is-show-more="true"
                  @change="dropdownClick"
                />
              </div>
            </div>
          </li>
        </List>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { KPI, Avatar, Tag, Button, Title } from "cavea-design-system";
import jsonToCsv from "@/lib/helpers/jsonToCsv";

export default {
  name: "NonOwnedCampaign",

  metaInfo() {
    return {
      title: this.campaign?.title ?? "Non Owned Campaign",
    };
  },

  components: {
    Title,
    Button,
    List: () => import("@/modules/List"),
    Dropdown: () => import("@/components/Dropdown"),
    Tag,
    Avatar,
    KPI,
  },

  data() {
    return {
      brandImages: {},
      showInactiveStreamers: true,
      liveChannels: null,
      sort: {
        by: "views",
        dir: false, // true = ascending; false = descending
      },
    };
  },

  computed: {
    ...mapGetters(["getCampaigns", "getBrands"]),

    /**
     * @summary gets campaign from route params
     * @returns {object} - campaign
     */
    campaign() {
      if (this.getCampaigns != null) {
        return this.getCampaigns.find((campaign) => campaign._id === this.$route.params.id);
      }

      return null;
    },

    /**
     *
     */
    computedCampaign() {
      // TODO: Refactor so this shit makes more sense

      if (!this.campaign) {
        return null;
      }

      // BUG: For some reason the state of campaigns were being mutated, when using: let campaign = this.campaign.
      // This hacky solves that by detaching the obj
      const jsonCampaign = JSON.stringify(this.campaign);
      const campaign = JSON.parse(jsonCampaign);

      if (this.sort.by === "handle") {
        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (a.handle > b.handle ? 1 : -1))
          : (campaign.handles = campaign.handles.sort((a, b) => (a.handle > b.handle ? -1 : -1)));
      } else if (this.sort.by === "views") {
        const sum = (stats) => {
          let total = 0;

          if (stats?.length) {
            for (const stat of stats) {
              if (stat?.views) {
                total += stat.views;
              }
            }
          }

          return total;
        };

        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (sum(a.statsId.stats) > sum(b.statsId.stats) ? 1 : -1))
          : campaign.handles.sort((a, b) => (sum(a.statsId.stats) > sum(b.statsId.stats) ? -1 : 1));
      } else if (this.sort.by === "clicks") {
        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (a.redirectorId.stats.length > b.redirectorId.stats.length ? 1 : -1))
          : campaign.handles.sort((a, b) => (a.redirectorId.stats.length > b.redirectorId.stats.length ? -1 : 1));
      } else if (this.sort.by === "ctr") {
        const ctr = (item) => {
          const viewsSum = (stats) => {
            let total = 0;

            if (stats?.length) {
              for (const stat of stats) {
                if (stat?.views) {
                  total += stat.views;
                }
              }
            }

            return total;
          };

          const clicks = item.redirectorId.stats.length || 0;

          let views = 0;
          if (item?.statsId?.stats && viewsSum(item.statsId.stats)) {
            views = viewsSum(item.statsId.stats);
          }

          if (clicks && views) {
            return clicks / views;
          }

          return 0;
        };

        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (ctr(a) > ctr(b) ? 1 : -1))
          : campaign.handles.sort((a, b) => (ctr(a) > ctr(b) ? -1 : 1));
      } else if (this.sort.by === "timesDisplayed") {
        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (a?.statsId?.stats?.length > b?.statsId?.stats?.length ? 1 : -1))
          : campaign.handles.sort((a, b) => (a?.statsId?.stats?.length > b?.stats?.statsId?.length ? -1 : 1));
      }

      if (!this.showInactiveStreamers) {
        campaign.handles = campaign.handles.filter((h) => h.statsId.stats.length > 0);
      }

      return campaign;
    },

    /**
     * @summary get times ad has been displayed
     * @returns {number}
     */
    getTotalTimesDisplayed() {
      let displayCount = 0;

      if (this.campaign?.handles?.length) {
        for (let i = 0; i < this.campaign.handles.length; ++i) {
          if (this.campaign?.handles[i]?.statsId?.stats?.length)
            displayCount += this.campaign.handles[i].statsId.stats.length;
        }
      }

      return displayCount;
    },

    /**
     * @summary get amount of link clicks
     * @returns {number}
     */
    getTotalLinkClick() {
      let linkClicks = 0;

      for (let i = 0; i < this.campaign.handles.length; ++i) {
        if (this.campaign?.handles[i]?.redirectorId?.stats) {
          linkClicks += this.campaign.handles[i].redirectorId.stats.length;
        }
      }

      return linkClicks;
    },

    /**
     * @summary get link ctr
     * @returns {number} ctr
     */
    getTotalLinkCtr() {
      let ctr = 0;

      if (this.getTotalViews && this.getTotalLinkClick) {
        ctr = ((this.getTotalLinkClick / this.getTotalViews) * 100).toFixed(2);
      }
      return ctr;
    },

    /**
     * @summary get average ad views
     * @returns {number} avgViews
     */
    getTotalAverageViews() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalTimesDisplayed) {
        avg = (this.getTotalViews / this.getTotalTimesDisplayed).toFixed(2);
      }

      return avg;
    },

    /**
     * @summary get total ad views
     * @returns {number} total views
     */
    getTotalViews() {
      let totalViews = 0;

      if (this.campaign?.handles?.length) {
        for (let i = 0; i < this.campaign.handles.length; ++i) {
          if (this.campaign?.handles[i]?.statsId?.stats?.length) {
            for (let j = 0; j < this.campaign.handles[i].statsId.stats.length; ++j) {
              if (this.campaign?.handles[i]?.statsId?.stats[j]?.views) {
                totalViews += this.campaign.handles[i].statsId.stats[j].views;
              }
            }
          }
        }
      }

      return totalViews;
    },
  },

  watch: {
    campaign: {
      handler() {
        if (!this.liveChannels) {
          this.getLiveChannels();
        }
        this.getBrandImg();
      },
    },
  },

  created() {
    if (this.getUserInfo?.type === "streamer") {
      this.$router.push("/");
    }
  },

  methods: {
    /**
     * @summary remove handle
     * @param {string} handle
     */
    async removeHandle(contentCreatorId) {
      const contentCreatorIndex = this.campaign.handles.findIndex((h) => h.contentCreatorId === contentCreatorId);

      const confirmation = await this.$swal({
        title: `Are you sure you want to ${this.campaign.handles[contentCreatorIndex].active ? "disable" : "enable"} ${
          this.campaign.handles[contentCreatorIndex].handle
        } on this campaign?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c13859",
        confirmButtonText: `Yes, ${this.campaign.handles[contentCreatorIndex].active ? "disable" : "enable"} it!`,
      });

      if (confirmation?.isConfirmed) {
        const HOST = process.env.VUE_APP_API_URL;
        const URL = `${HOST}/ad-campaign/switch-handle-active/${this.campaign._id}/${contentCreatorId}`;

        await axios
          .put(URL)
          .then(() => {
            this.$swal({
              icon: "success",
              title: `Handle has been ${this.campaign.handles[contentCreatorIndex].active ? "disabled" : "enabled"}`,
              showConfirmButton: false,
              timer: 1000,
            });

            this.fetchCampaigns();
          })
          .catch((err) => {
            console.error("removeHandle", err);

            this.$swal({
              icon: "error",
              title: "Oops, something went wrong!",
              text: "Please try again later",
            });
          });
      }
    },

    /**
     * @summary get total ad views by streamer handle
     * @param {string} handle - streamer handle
     * @returns {number} totalViews
     */
    getStreamerViews(handle) {
      let totalViews = 0;

      const streamer = this.campaign.handles.find((item) => item.handle === handle);

      if (streamer?.statsId?.stats) {
        for (const stat of streamer.statsId.stats) {
          totalViews += stat.views;
        }
      }

      return totalViews;
    },

    /**
     * @summary streamer chat bot link clicks
     * @param {string} handle - streamer handle
     */
    getStreamerClicks(handle) {
      let linkClicks = 0;

      const streamer = this.campaign.handles.find((item) => item.handle === handle);

      if (streamer?.redirectorId?.stats?.length) {
        linkClicks = streamer.redirectorId.stats.length;
      }

      return linkClicks;
    },

    /**
     * @summary streamer campaign ctr
     * @param {string} handle - streamer handle
     */
    getStreamerCtr(handle) {
      let ctr = 0;

      const views = this.getStreamerViews(handle);
      const clicks = this.getStreamerClicks(handle);

      if (views && clicks) {
        ctr = ((clicks / views) * 100).toFixed(2);
      }
      return ctr;
    },

    /**
     * @summary get streamer display times
     * @param {string} handle
     */
    getStreamerDisplayTimes(handle) {
      let displayTimes = 0;

      const streamer = this.campaign.handles.find((item) => item.handle === handle);

      if (streamer?.statsId?.stats) {
        displayTimes = streamer.statsId.stats.length;
      }

      return displayTimes;
    },

    /**
     * @summary detect which button is clicked in handle dropdown
     * @param {event} e
     */
    dropdownClick(e) {
      // FIXME: There must be a better way to detect which item is clicked

      if (e?.item?.label?.toLowerCase() === "copy stream code") {
        const tempElement = document.createElement("input");
        const overlayLink = `https://overlay.netwrk.gg/?id=${e.item.id}`;
        tempElement.value = overlayLink;
        document.body.appendChild(tempElement);

        tempElement.select();
        document.execCommand("copy");
        document.body.removeChild(tempElement);
      }
    },

    handleSort(sortBy) {
      if (this.sort?.by === sortBy) {
        this.sort.dir = !this.sort.dir;
      } else {
        this.sort.dir = sortBy === "handle";
      }

      this.sort.by = sortBy;
    },

    async getLiveChannels() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/active-overlay-streamers`;

      const handles = this.campaign.handles.map((h) => h.handle);

      this.liveChannels = await axios
        .post(URL, { handles })
        .then((res) => res.data.handles)
        .catch((err) => {
          console.error("error getting live channels", err);
          return [];
        });
    },

    handleStreamStatus(handle) {
      return this.liveChannels?.findIndex((h) => h.toLowerCase() === handle.toLowerCase()) !== -1;
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/frymealiiiver/avatar.png";
    },

    exportStreamerDataCSV() {
      const reducer = (a, b) => a + (b?.views || 0);

      const data = JSON.parse(JSON.stringify(this.campaign?.handles))?.map((h) => ({
        handle: h?.handle,
        views: (h?.statsId?.stats).reduce(reducer, 0),
        times_displayed: h?.statsId?.stats?.length || 0,
        clicks: h?.redirectorId?.stats?.length || 0,
        ctr: `${((h?.redirectorId?.stats?.length || 0) / h?.statsId?.stats?.reduce(reducer, 0)) * 100}%`,
      }));

      const title = this.campaign?.title?.toLowerCase()?.trim()?.replaceAll(" ", "_") || "campaign";

      jsonToCsv(
        data?.sort((a, b) => (a?.views > b?.views ? -1 : 1)) || data,
        `netwrk_${title}_export_${new Date().toISOString().split("T")[0]}.csv`
      );
    },

    async getBrandImg() {
      if (this.getCampaigns) {
        for (const campaign of this.getCampaigns) {
          if (campaign?.brand?.id && this.brandImages[`${campaign?.brand?.id}`] === undefined) {
            const brandIndex = this.getBrands?.findIndex((b) => b?._id === campaign?.brand?.id);

            if (brandIndex !== -1) {
              this.brandImages[`${campaign?.brand?.id}`] = this.getBrands[brandIndex]?.logo || null;
            } else {
              const HOST = process.env.VUE_APP_API_URL;
              const URL = `${HOST}/brand-logo/${campaign?.brand?.id}`;

              await axios
                .get(URL)
                .then((res) => {
                  this.brandImages[`${campaign?.brand?.id}`] = res?.data?.image || null;
                })
                .catch((error) => {
                  console.error("fetchNonOwnedBrandImage error", error);
                  return null;
                });
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
